import React, { useEffect, useState } from "react";
import "./Reports.scss";
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    TextField,
    IconButton,
    Tooltip,
    Zoom,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Checkbox,
    FormGroup,
} from "@material-ui/core";
import {
    baseApiURL,
    tableIconsInputScreen,
} from "../../../../Utilities/Utility";
import axios from "axios";
import MaterialTable from "material-table";
import Loader from "../../../../components/Loader/Loader";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import OnlinePactNavBar from "../OnlinePactNavBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { useHistory } from "react-router-dom";
import { detReportsAction } from "../../../../Redux/Actions/actions";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Reports() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    //const api = axios.create({
    //    baseURL: "Reports",
    //});
    const reportsAPI = axios.create({
        baseURL: "api/Reports",
    });
    const api = axios.create({
        baseURL: "Complaint",
    });
    const closeAPI = axios.create({
        baseURL: "CloseComplaint",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [complaintTypeValue, setComplaintTypeValue] = useState("");
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    useEffect(() => {
        // getComplaintsList();
        getReasonMaster();
        getDispatchCentreMaster();
        getOfficeMaster();
        getCustomerMaster();
        getAllComplaintsList();
        getComplaintNumber();
        dispatch(detReportsAction({}));
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [matTableData, setMatTableData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [expFileName, setExpFileName] = useState("Complaints List");
    const [reportFieldsVal, setReportFieldsVal] = useState({
        fromDate: null,
        toDate: null,
        grThan15Days: false,
        compType: "",
    });
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // const getComplaintsList = () => {
    //     if (complaintTypeValue == "1" || complaintTypeValue == "2")
    //      getSelectedComplaintList();
    //     else getAllComplaintsList();
    // };

    const getAllComplaintsList = () => {
        setIsLoading(true);
        setIsTableLoading(true);
        reportsAPI
            .get("/UserComplaintsListDC/" + userDetails.loginId, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then(function (response) {
                // handleSnackOpen("Complaint Saved Successfully.", "success");
                console.log("Complaints List", response.data);
                setMatTableData(response.data);
                setIsLoading(false);
                setIsTableLoading(false);
            })
            .catch(function (error) {
                // handleSnackOpen("Error in saving.", "error");
                setIsLoading(false);
                setIsTableLoading(false);
                console.log(error);
            });
    };

    const [complaintDetailsInput, setComplaintDetailsInput] = useState({
        complaintNoSearchString: "",
        reasonSearchString: "",
        dispatchCenterSearchString: "", // should look up with dept master
        dispatchCenterSearchStringArr: [],
        salesOfficeSearchString: "",
        customerSearchString: "",
    });
    const [complaintNoSearchStringUI, setComplaintNoSearchStringUI] = useState(
        complaintDetailsInput.complaintNoSearchString
    );
    const [reasonSearchStringUI, setReasonSearchStringUI] = useState(
        complaintDetailsInput.reasonSearchString
    );
    const [dispatchCenterSearchStringUI, setDispatchCenterSearchStringUI] =
        useState(complaintDetailsInput.dispatchCenterSearchString);
    const [salesOfficeSearchStringUI, setSalesOfficeSearchStringUI] = useState(
        complaintDetailsInput.salesOfficeSearchString
    );
    const [customerSearchStringUI, setCustomerSearchStringUI] = useState(
        complaintDetailsInput.customerSearchString
    );

    const inputFieldsChange = (e) => {
        const fieldVal = e.target.value;
        const fieldName = e.target.name;
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            [fieldName]: fieldVal,
        }));

        switch (fieldName) {
            case "complaintNoSearchString":
                setComplaintNoSearchStringUI(fieldVal);
                break;
            case "reasonSearchString":
                setReasonSearchStringUI(fieldVal);
                break;
            case "dispatchCenterSearchString":
                setDispatchCenterSearchStringUI(fieldVal);
                break;
            case "salesOfficeSearchString":
                setSalesOfficeSearchStringUI(fieldVal);
                break;
            case "customerSearchString":
                setCustomerSearchStringUI(fieldVal);
                break;
            default:
                break;
        }
    };

    const dispatchDropDownChange = (selected) => {
        console.log(selected);
        const selectedOptID = [];
        selected.forEach((element) => {
            selectedOptID.push(element.id);
        });
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            dispatchCenterSearchStringArr: selectedOptID,
        }));
    };

    const customerStrChange = (event, value) => {
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            customerSearchString: value,
        }));
        setCustomerSearchStringUI(value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setReportFieldsVal(() => ({
            ...reportFieldsVal,
            fromDate: date,
        }));
    };
    const handleToDateChange = (date) => {
        if (
            date.toLocaleDateString("en-US") <=
            selectedDate.toLocaleDateString("en-US")
        ) {
            handleSnackOpen("Select different date", "warning");
            return;
        }

        setSelectedToDate(date);
        setReportFieldsVal(() => ({
            ...reportFieldsVal,
            toDate: date,
        }));
    };
    const complaintNorChange = (event, value) => {
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            complaintNoSearchString: value,
        }));
        setComplaintNoSearchStringUI(value);
    };
    const [canShowMaterialTable, setCanShowMaterialTable] = useState(false);
    const newSearchComplaint = () => {
        console.log("Inputs", complaintDetailsInput);
    };
    const searchComplaint = () => {
        setIsLoading(true);
        setIsTableLoading(true);
        setCanShowMaterialTable(true);
        var status = complaintTypeValue;

        if (status == "1") setExpFileName("Open Complaints");
        else if (status == "2") setExpFileName("Closed Complaints");
        else setExpFileName("Complaints List");

        let searchArgs = {
            ComplaintNumber: complaintDetailsInput.complaintNoSearchString,
            Reason:
                complaintDetailsInput.reasonSearchString != ""
                    ? complaintDetailsInput.reasonSearchString
                    : 0,
            DispatchCenter:
                complaintDetailsInput.dispatchCenterSearchString != ""
                    ? complaintDetailsInput.dispatchCenterSearchString
                    : 0,
            // Sakshi - multi select dispatch center
            DispatchCenterArr:
                complaintDetailsInput.dispatchCenterSearchStringArr != []
                    ? complaintDetailsInput.dispatchCenterSearchStringArr
                    : [], //Sakshi - pass whatever you want in case if none of the option is selected
            SalesOffice:
                complaintDetailsInput.salesOfficeSearchString != ""
                    ? complaintDetailsInput.salesOfficeSearchString
                    : 0,
            CustomerName: complaintDetailsInput.customerSearchString,
            CreatedDate: selectedDate != "" ? selectedDate : null,
            ToDate: selectedToDate != "" ? selectedToDate : null,
            CheckBox: reportFieldsVal.grThan15Days,
            Status: status,
            loginId: userDetails.loginId,
        };

        //const StaticObj = {
        //    ComplaintNumber: 9069406861,
        //    Reason: 3,
        //    DispatchCenter: 4,
        //    SalesOffice: 'Exports',
        //    Customer: 3,
        //}

        reportsAPI
            .post("/SearchComplaintListDC", searchArgs, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then(function (response) {
                // handleSnackOpen("Complaint Saved Successfully.", "success");
                // setIsLoading(false);
                //const formattedDate = response.data[0][0].CreatedDate.toLocaleDateString("en-GB", { day: "numeric", month: "long", year: "numeric" })
                //console.log("Date format change", formattedDate);
                setMatTableData(response.data);
                console.log("Motor Details", response.data);
                setIsLoading(false);
                setIsTableLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                setIsTableLoading(false);
                handleSnackOpen("Error !!", "error");
                // console.log(error);
            });
    };

    // const getSelectedComplaintList = () => {
    //     var status = complaintTypeValue;
    //     reportsAPI
    //         .get("/GetSelectedComplaintList/" + status + "/" + userDetails.loginId)
    //         .then(function (response) {
    //             // handleSnackOpen("Complaint Saved Successfully.", "success");
    //             console.log("Selected Complaints List", response.data);
    //             setMatTableData(response.data);
    //             setIsLoading(false);
    //         })
    //         .catch(function (error) {
    //             handleSnackOpen("Error !!", "error");
    //             setIsLoading(false);
    //             console.log(error);
    //         });
    // };

    const handleCheckBoxChange = (event) => {
        console.log("Check box changed");
        const fieldVal = event.target.checked;

        setReportFieldsVal((reportFieldsVal) => ({
            ...reportFieldsVal,
            grThan15Days: fieldVal,
        }));
    };

    const handleRadioChange = (event) => {
        const val = event.target.value;
        setComplaintTypeValue(val);

        setReportFieldsVal((reportFieldsVal) => ({
            ...reportFieldsVal,
            compType: event.target.value,
        }));

        // reportsAPI
        //   .get("/GetSelectedComplaintList/" + status + "/" + userDetails.loginId)
        //   .then(function (response) {
        //     // handleSnackOpen("Complaint Saved Successfully.", "success");
        //     setIsLoading(false);
        //     console.log("Selected Complaints List", response.data);
        //     setMatTableData(response.data);
        //   })
        //   .catch(function (error) {
        //     // handleSnackOpen("Error in saving.", "error");
        //     setIsLoading(false);
        //     console.log(error);
        //   });
    };

    const [reasonMasterData, setReasonMasterData] = useState([]);
    const getReasonMaster = () => {
        setIsLoading(true);
        api
            .get("/getReasonmaster", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                // reasonMasterData = res.data;
                setReasonMasterData(res.data);
                console.log("Reason Master", res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    };
    const [dispatchMasterData, setDispatchMasterData] = useState([]);
    const getDispatchCentreMaster = () => {
        api
            .get("/getDispatchcentremaster", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Dispatch Master", res.data);
                setDispatchMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    // Sakshi - Refer this to add new columns in dispatch center master
    const dispatchMasterDataStatic = [
        {
            id: 1,
            value: 1,
            dispatchCentreId: "4162",
            dispatchCentreName: "4162-Logistics Service",
            createdDate: "2022-05-02T15:42:10.49",
            createdBy: 1,
            modifiedDate: "2022-10-07T14:38:38.56",
            modifiedBy: 1,
            label: "4162-Logistics Service",
            isActive: true,
            email: "sachin.prabhat.ext@siemens.com",
            superiorMail: "sachin.prabhat.ext@siemens.com",
            userId: 30008,
            tblComplaintDetails: [],
        },
        {
            id: 2,
            value: 2,
            dispatchCentreId: "6362",
            dispatchCentreName: "6362-Siemens Ltd-Chennai Depot",
            label: "6362-Siemens Ltd-Chennai Depot",
            createdDate: "2022-05-02T15:42:10.49",
            createdBy: 1,
            modifiedDate: "2022-10-07T12:24:32.083",
            modifiedBy: 1,
            isActive: true,
            email: "sakshi.dabade.ext@siemens.com",
            superiorMail: "sakshi.dabade.ext@siemens.com",
            userId: 6,
            tblComplaintDetails: [],
        },
        {
            id: 3,
            value: 3,
            dispatchCentreId: "6462-DI",
            dispatchCentreName: "6462-DI-Systems Sales",
            label: "6462-DI-Systems Sales",
            createdDate: "2022-05-02T15:42:10.49",
            createdBy: 1,
            modifiedDate: "2022-10-07T14:38:56.593",
            modifiedBy: 1,
            isActive: true,
            email: "sakshi.dabade.ext@siemens.com",
            superiorMail: "sakshi.dabade.ext@siemens.com",
            userId: 20007,
            tblComplaintDetails: [],
        },
        {
            id: 4,
            value: 4,
            dispatchCentreId: "6462-SI-EP",
            dispatchCentreName: "6462-SI-Product Sales",
            label: "6462-SI-Product Sales",
            createdDate: "2022-05-02T15:42:10.49",
            createdBy: 1,
            modifiedDate: "2022-10-07T11:24:23.863",
            modifiedBy: 1,
            isActive: true,
            email: "sakshi.dabade.ext@siemens.com",
            superiorMail: "sakshi.dabade.ext@siemens.com",
            userId: null,
            tblComplaintDetails: [],
        },
        {
            id: 5,
            value: 5,
            dispatchCentreId: "7462",
            dispatchCentreName: "7462-Siemens Ltd, Chennai Office",
            label: "7462-Siemens Ltd, Chennai Office",
            createdDate: "2022-05-02T15:42:10.49",
            createdBy: 1,
            modifiedDate: "2022-05-02T15:42:10.49",
            modifiedBy: 1,
            isActive: true,
            email: "sakshi.dabade.ext@siemens.com",
            superiorMail: "sakshi.dabade.ext@siemens.com",
            userId: 20007,
            tblComplaintDetails: [],
        },
    ];

    const [officeMasterData, setOfficeMasterData] = useState([]);
    const getOfficeMaster = () => {
        closeAPI
            .get("/getOfficemaster", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Office Master", res.data);
                setOfficeMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    const [customerMasterData, setCustomerMasterData] = useState([]);
    const getCustomerMaster = () => {
        reportsAPI
            .get("/getCustomerMaster", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Customer Master", res.data);
                setCustomerMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const [complaintNumberData, setComplaintNumberData] = useState([]);
    const getComplaintNumber = () => {
        reportsAPI
            .get("/getComplaintNumber/" + userDetails.loginId, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Complaint Master", res.data);
                setComplaintNumberData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const ResetSearch = () => {
        console.log("Reset Button Clicked");
        setComplaintDetailsInput({
            complaintNoSearchString: "",
            reasonSearchString: "",
            dispatchCenterSearchString: "",
            dispatchCenterSearchStringArr: [],
            salesOfficeSearchString: "",
            customerSearchString: "",
        });
        setReportFieldsVal({
            fromDate: null,
            toDate: null,
            grThan15Days: false,
            compType: "",
        });

        setComplaintNoSearchStringUI("");
        setReasonSearchStringUI("");
        setDispatchCenterSearchStringUI("");
        setSalesOfficeSearchStringUI("");
        setCustomerSearchStringUI("");
        setSelectedDate(null);
        setSelectedToDate(null);
        setComplaintTypeValue("");

        getAllComplaintsList();
    };
    const reportsTableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        exportAllData: true,
        exportButton: { csv: true },
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        maxBodyHeight: "600px",
        sorting: true,
        exportFileName: expFileName,
    };
    const closeCompColumns = [
        {
            title: "View",
            width: "10%",
            render: (rowData) => ViewIconButton(rowData),
        },
        { title: "Complaint No", field: "complaintNumber", editable: "never" },
        { title: "Name", field: "name", editable: "never" },
        { title: "Department", field: "departmentName", editable: "never" },
        { title: "Sales Office", field: "salesOffice", editable: "never" },
        { title: "Email", field: "email", editable: "never" },
        { title: "Reason", field: "reasonName", editable: "never" },
        { title: "Sales Engineer EMail", field: "seemail", editable: "never" },
        { title: "Transporter", field: "transporter", editable: "never" },
        { title: "Reference Number", field: "referenceName", editable: "never" },
        { title: "Created By", field: "userName", editable: "never" },
        { title: "Customer Name", field: "customerName", editable: "never" },
        {
            title: "Created Date",
            field: "createdDate",
            editable: "never",
            render: (rowData) => {
                if (rowData.createdDate != null && rowData.createdDate != undefined) {
                    const dateStr = new Date(rowData.createdDate);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                    };
                    const retDateStr = dateStr.toLocaleDateString("en-US", options);
                    return retDateStr;
                }
            },
        },
        {
            title: "Dispatch Center",
            field: "dispatchCenterName",
            editable: "never",
        },
        { title: "Invoice Number", field: "invoicePosition", editable: "never" },
        { title: "Invoice Position", field: "item", editable: "never" },
        { title: "Order No", field: "orderNo", editable: "never" },
        { title: "Order Item", field: "orderItem", editable: "never" },
        { title: "Quantity", field: "quantity", editable: "never" },
        { title: "MLFB", field: "mlfb", editable: "never" },
        { title: "Affected Quantity", field: "enteredQuantity", editable: "never" },
        { title: "Affected MLFB", field: "enteredMlfb", editable: "never" },
        { title: "BU", field: "bu", editable: "never" },
        { title: "BSS", field: "bss", editable: "never" },
        { title: "LR No", field: "lrno", editable: "never" },
        { title: "LR Date", field: "lrdate", editable: "never" },
        { title: "Division", field: "division", editable: "never" },
        { title: "Status", field: "status", editable: "never" },
        { title: "Remarks", field: "remarks", editable: "never" },
        { title: "Closing Remarks", field: "closingRemarks", editable: "never" },
        { title: "Closed By", field: "closedByName", editable: "never" },
        {
            title: "Closed Date",
            field: "closedDate",
            editable: "never",
            render: (rowData) => {
                if (rowData.closedDate !== null && rowData.closedDate !== undefined) {
                    const dateStr = new Date(rowData.closedDate);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                    };
                    const retDateStr = dateStr.toLocaleDateString("en-US", options);
                    return retDateStr;
                }
            },
        },
        { title: "Acceptance", field: "acceptance", editable: "never" },
        { title: "Escalated By", field: "escalatedByName", editable: "never" },
        { title: "Escalated To", field: "escalatedToName", editable: "never" },
        {
            title: "Escalated On",
            field: "escalatedDate",
            editable: "never",
            render: (rowData) => {
                if (
                    rowData.escalatedDate !== null &&
                    rowData.escalatedDate !== undefined
                ) {
                    const dateStr = new Date(rowData.escalatedDate);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                    };
                    const retDateStr = dateStr.toLocaleDateString("en-US", options);
                    return retDateStr;
                }
            },
        },
        { title: "Pending With", field: "dispatchAssigned", editable: "never" },
        // {
        //   title: "Edit",
        //   width: "10%",
        //   render: (rowData) => editIconButton(rowData),
        // },
        // {
        //   title: "Delete",
        //   width: "10%",
        //   render: (rowData) => downloadIconButton(rowData),
        // },
    ];

    const reportFieldsChange = (e) => {
        const fieldVal = e.target.value;
        const fieldName = e.target.name;
        // setReportFieldsVal((reportFieldsVal) => ({
        //   ...reportFieldsVal,
        //   [fieldName]: fieldVal,
        // }));
    };

    function viewIconClick(rowData) {
        history.push("/detailedReport");
        dispatch(detReportsAction(rowData));
    }

    const ViewIconButton = (rowData) => {
        return (
            <IconButton
                aria-label="ViewIconOutlined"
                onClick={() => viewIconClick(rowData)}
            >
                <Tooltip title="View">
                    <RemoveRedEyeOutlinedIcon />
                </Tooltip>
            </IconButton>
        );
    };

    return (
        <div className="reportsCont">
            <OnlinePactNavBar />
            <div>
                <DialogTitle id="responsive-dialog-title">Download Reports</DialogTitle>
            </div>

            <Grid container spacing={2} className="mt-05">
                <Grid item xs={12} sm={4} md={3} className="customerField">
                    <Autocomplete
                        options={complaintNumberData}
                        getOptionLabel={(option) => option}
                        id="debug"
                        clearOnEscape={true}
                        debug
                        value={complaintNoSearchStringUI}
                        onChange={complaintNorChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Complaint Number" margin="none" />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            // margin="normal"
                            id="date-picker-dialog"
                            label="From Date"
                            format="MM/dd/yyyy"
                            placeholder="MM/dd/yyyy"
                            fullWidth
                            maxDate={new Date()}
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            // margin="normal"
                            id="date-picker-dialog"
                            label="To Date"
                            format="MM/dd/yyyy"
                            placeholder="MM/dd/yyyy"
                            fullWidth
                            maxDate={new Date()}
                            value={selectedToDate}
                            onChange={handleToDateChange}
                            // onChange={handleDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Reason</InputLabel>
                        <Select
                            name="reasonSearchString"
                            label="demo-simple-select-helper-label"
                            id="reasonSearchString"
                            value={reasonSearchStringUI}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {reasonMasterData.map((item, index) => (
                                <MenuItem key={index} value={item.id} name={item.reasonName}>
                                    {item.reasonName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/*<Grid item xs={12} sm={4} md={3}>*/}
                {/*    <FormControl fullWidth variant="filled">*/}
                {/*        <InputLabel id="efficiency-label">Dispatch Center</InputLabel>*/}
                {/*        <Select*/}
                {/*            name="dispatchCenterSearchString"*/}
                {/*            label="demo-simple-select-helper-label"*/}
                {/*            id="dispatchCenterSearchString"*/}
                {/*            value={dispatchCenterSearchStringUI}*/}
                {/*            onChange={inputFieldsChange}*/}
                {/*        >*/}
                {/*            <MenuItem value="">--Select--</MenuItem>*/}

                {/*            {dispatchMasterData.map((item, index) => (*/}
                {/*                <MenuItem*/}
                {/*                    key={index}*/}
                {/*                    value={item.id}*/}
                {/*                    name={item.dispatchCentreName}*/}
                {/*                >*/}
                {/*                    {item.dispatchCentreName}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}

                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    className="d-flex jc-flex-start width-100 division_field"
                    fullWidth
                >
                    <ReactMultiSelectCheckboxes
                        options={dispatchMasterData} // Sakshi - Replace this with "dispatchMasterData" after you have added new columns
                        placeholderButtonLabel="Dispatch Center"
                        onChange={(e) => dispatchDropDownChange(e)}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Sales Office</InputLabel>
                        <Select
                            name="salesOfficeSearchString"
                            label="demo-simple-select-helper-label"
                            id="salesOfficeSearchString"
                            value={salesOfficeSearchStringUI}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {officeMasterData.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.officeId}
                                    name={item.officeName}
                                >
                                    {item.officeName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3} className="customerField">
                    <Autocomplete
                        options={customerMasterData}
                        getOptionLabel={(option) => option}
                        id="debug"
                        clearOnEscape={true}
                        debug
                        value={customerSearchStringUI}
                        onChange={customerStrChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" margin="none" />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className="checkBoxCont align-center">
                <Grid item xs={12} sm={4} md={2}>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value={reportFieldsVal.grThan15Days}
                                checked={reportFieldsVal.grThan15Days}
                                control={<Checkbox onClick={handleCheckBoxChange} />}
                                label="Complaints >15 Days"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className="d-flex jc-center">
                    <FormControl component="fieldset">
                        <RadioGroup
                            columns
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel
                                value="1"
                                checked={complaintTypeValue == "1"}
                                control={<Radio color="primary" />}
                                label="Open Complaints"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <RadioGroup
                            columns
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel
                                value="2"
                                checked={complaintTypeValue == "2"}
                                control={<Radio color="primary" />}
                                label="Close Complaints"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2} sm={2} fullWidth>
                    <Button
                        className="pt-button--primary bulk-upload-submit-btn"
                        type="button"
                        onClick={searchComplaint}
                        fullWidth
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12} md={2} sm={2} fullWidth>
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                        fullWidth
                        onClick={ResetSearch}
                    >
                        Show All
                    </Button>
                </Grid>
                <Grid item xs={12} md={2} sm={2} fullWidth>
                    <a
                        href="https://siemensapc-my.sharepoint.com/:x:/g/personal/mamta_mestry_siemens_com/ERmqoIhK4fJFsgSE3c37c1YBSa-AGllyNGyGuFgU6r8EpA?e=Ql8zVW&ovuser=38ae3bcd-9579-4fd4-adda-b42e1495d55a%2Csakshi.dabade.ext%40siemens.com&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzAzMDUwMTExMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                        download="RTC_ManualUpload"
                        target="_blank"
                    >
                        <Button className="pt-button--secondary bulk-upload-submit-btn">
                            RECO Archives
                        </Button>
                    </a>
                </Grid>
            </Grid>

            <div className="matTableClass">
                <Grid item xs={12}>
                    <MaterialTable
                        columns={closeCompColumns}
                        data={matTableData}
                        icons={tableIconsInputScreen}
                        isLoading={isTableLoading}
                        options={reportsTableOptions}
                        localization={{
                            toolbar: {
                                exportTitle: "Download Report",
                                // exportCSVName: "Download Report",
                            },
                        }}
                    />
                </Grid>
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </div>
    );
}
